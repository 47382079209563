window.addEventListener("load", bindEventListeners, false);

function bindEventListeners() {
    $("#menu-button").on("click", toggleShowContentNavigation);
}

function toggleShowContentNavigation() {
    $("#content-navigation").toggleClass("show-content-navigation");
    $("#menu-button").toggleClass("x-icon");
    $(".menu-item").each(function() {
        let a = $(this).find("a");
        if (a.prop("id") == window.location.pathname) {
            $(this).toggleClass("active");
            a.toggleClass("dead").removeAttr("href");
        }
        $(this).toggleClass("show");
    })
}